import Polyglot from 'node-polyglot';
import * as Sentry from '@sentry/browser';
import PoliglotMissingKeyError from "@/Services/Errors/PoliglotMissingKeyError";

const polyglot = new Polyglot({
    allowMissing: true,
    onMissingKey: (e) => {
        Sentry.captureException(new PoliglotMissingKeyError(e));
    }
});

polyglot.extend({
    "en": {
        'form_errors': {
            'email_address_exists_error': 'Oops...Your email already exists in our records. Please try again using a different email.',
            'invalid_email_disposable': 'We cannot accept this email, please use a different email address.',
            'invalid_email_reason_rejected': 'Oops...Your email seems to be incorrect, please check for any errors and try again',
            'invalid_email_reason_unknown': 'Something went wrong, please change email or try again',
            'invalid_email_role': 'We cannot accept this email, please use a different email address.',
            'invalid_email_w_suggestion': 'Oops... Did you mean %{suggestion}',
            'checkbox_error': 'Please agree to continue',
            'email_required_error': 'Your email is required to continue',
            'email_not_valid_error': 'Please enter a valid email address',
            'name_not_valid_length_error': 'Please enter your full name',
            'name_contain_numbers_error': 'Please use only letters'
        },
        'modals_slug': {
            'about_us': 'about-us',
            'privacy_policy': 'privacy-policy',
            'terms': 'terms'
        },
        'footer_links': {
            'about_us': 'About us',
            'privacy_policy': 'Privacy Policy',
            'terms': 'Terms of Use'
        },
        'page404': {
            'errorNum': '404',
            'mainHeader1': "oh!",
            'mainHeader2': "Something went wrong here",
            'subHeader': "Take me back to the homepage"
        }
    },
    "ro": {
        'form_errors': {
            'email_address_exists_error': 'Oops ... E-mailul tău există deja în înregistrările noastre. Încerca din nou folosind un e-mail diferit',
            'invalid_email_disposable': 'Nu putem accepta acest e-mail, așa că te rugăm să utilizezi o altă adresă de e-mail.',
            'invalid_email_reason_rejected': 'Oops ... E-mailul tău pare incorect, verifică dacă există erori și încearcă din nou.',
            'invalid_email_reason_unknown': 'Ceva nu a funcționat cum trebuie, așa că te rugăm să schimbi e-mailul sau să încerci din nou.',
            'invalid_email_role': 'Nu putem accepta acest e-mail, așa că te rugăm să folosești o altă adresă de e-mail.',
            'invalid_email_w_suggestion': 'Oops ... Ai intenționat să %{suggestion}',
            'checkbox_error': 'Te rugăm să fii de acord pentru a putea continua',
            'email_required_error': 'Este necesar e-mailul tău pentru a continua',
            'email_not_valid_error': 'Te rugăm să introduci o adresă de email validă',
            'name_not_valid_length_error': 'Te rugăm să-ți introduci numele complet',
            'name_contain_numbers_error': 'Te rugăm să folosești doar litere'
        },
        'modals_slug': {
            'about_us': 'despre-noi',
            'privacy_policy': 'politica-de-confidentialitate',
            'terms': 'termenii-de-utilizare'
        },
        'footer_links': {
            'about_us': 'Despre noi',
            'privacy_policy': 'Politica de Confidențialitate',
            'terms': 'Termenii de Utilizare'
        },
        'page404': {
            'errorNum': '404',
            'mainHeader1': "OOPS!",
            'mainHeader2': "Ceva nu a funcționat cum trebuie.",
            'subHeader': "Mergi înapoi la pagina principală"
        }
    },
    "jp": {
        'form_errors': {
            'email_address_exists_error': 'このメールアドレスは既に使用されています。違うメールアドレスで試してください',
            'invalid_email_disposable': 'このメールアドレスは使用できません。違うメールアドレスで再試行してください',
            'invalid_email_reason_rejected': 'エラー：メールアドレスが間違っています。エラーを確認して、もう一度お試しください。',
            'invalid_email_reason_unknown': 'エラーが発生しました。メールアドレスを変更するか、もう一度お試しください。',
            'invalid_email_role': 'このメールアドレスは使用できません。違うメールアドレスで再試行してください',
            'invalid_email_w_suggestion': 'もしかしたら %{suggestion} ですか？ ',
            'checkbox_error': '同意して続ける',
            'email_required_error': '続行するにはメールアドレスが必要になります',
            'email_not_valid_error': '有効なメールアドレスを入力してください',
            'name_not_valid_length_error': '氏名を記入してください',
            'name_contain_numbers_error': '文字のみを使用してください'
        },
        'modals_slug': {
            'about_us': 'about-us',
            'privacy_policy': 'privacy-policy',
            'terms': 'terms'
        },
        'footer_links': {
            'about_us': 'About us',
            'privacy_policy': 'プライバシーポリシー',
            'terms': '利用規約'
        },
        'page404': {
            'errorNum': '404',
            'mainHeader1': "oh!",
            'mainHeader2': "Something went wrong here",
            'subHeader': "Take me back to the homepage"
        }
    },
    "kr": {
        'form_errors': {
            'email_address_exists_error': '이 메일 주소는 이미 사용되고 있습니다. 다른 메일 주소를 사용해보십시오.',
            'invalid_email_disposable': '이 메일 주소는 사용할 수 없습니다. 다른 메일 주소를 사용해보십시오.',
            'invalid_email_reason_rejected': '오류: 메일 주소가 잘못되었습니다. 오류를 확인하고 다시 시도하십시오.',
            'invalid_email_reason_unknown': '오류가 발생했습니다. 메일 주소를 변경하거나 다시 시도하십시오.',
            'invalid_email_role': '이 메일 주소는 사용할 수 없습니다. 다른 메일 주소를 사용해보십시오.',
            'invalid_email_w_suggestion': '혹시 %{suggestion} 입니까? ',
            'checkbox_error': '동의하고 계속하기',
            'email_required_error': '계속하려면 메일 주소가 필요합니다',
            'email_not_valid_error': '유효한 메일 주소를 입력하십시오',
            'name_not_valid_length_error': '성명을 기입하십시오',
            'name_contain_numbers_error': '글자만 사용하십시오'
        },
        'modals_slug': {
            'about_us': 'about-us',
            'privacy_policy': 'privacy-policy',
            'terms': 'terms'
        },
        'footer_links': {
            'about_us': 'About us',
            'privacy_policy': '개인정보 보호정책',
            'terms': '이용 규약'
        },
        'page404': {
            'errorNum': '404',
            'mainHeader1': "oh!",
            'mainHeader2': "Something went wrong here",
            'subHeader': "Take me back to the homepage"
        }
    },
    "it": {
        'form_errors': {
            'email_address_exists_error': '',
            'invalid_email_disposable': '',
            'invalid_email_reason_rejected': '',
            'invalid_email_reason_unknown': '',
            'invalid_email_role': '',
            'invalid_email_w_suggestion': '',
            'checkbox_error': '',
            'email_required_error': '',
            'email_not_valid_error': '',
            'name_not_valid_length_error': '',
            'name_contain_numbers_error': ''
        },
        'modals_slug': {
            'about_us': '',
            'privacy_policy': '',
            'terms': ''
        },
        'footer_links': {
            'about_us': '',
            'privacy_policy': '',
            'terms': ''
        },
        'page404': {
            'errorNum': '404',
            'mainHeader1': "OPS!",
            'mainHeader2': "Ci dispiace, ma la pagina che stai cercando non esiste.",
            'subHeader': ""
        }
    }
});

export default polyglot;