import React from "react";
import snakes_box_bg from "../../../../assets/game-bg.png";
import SnakePicker from "../snakePicker/snakePicker";
import InstallBox from "../installBox/installBox";
import ReqTypes from "../reqTypes/ReqTypes";
import './actionBox.scss';

export default class ActionBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSelected: false,
            snakeColor: null,
        }
        this.selectSnake = this.selectSnake.bind(this);
    }

    selectSnake(snakeColor) {
     this.props.ga.customEvent('Click', `${snakeColor} Snake color selected`);

        this.setState({
            isSelected: true,
            snakeColor: snakeColor,
        });
    }

    render() {
        return (
            <div className={'action-box-container'}>
                <div className={'action-box'}>
                    <img src={snakes_box_bg} alt="Box" className={'action-box-bg'}/>
                    <div className={'box-body'}>
                        <SnakePicker
                            selectSnake={this.selectSnake}
                            snakeSelected={this.state.snakeColor}
                        />
                        <InstallBox
                            btnType={this.props.btnType}
                            onClick={this.props.onClick}
                            playClick={this.props.playClick}
                            installing={this.props.installing}
                        />
                        <ReqTypes/>
                    </div>
                </div>
            </div>
        )
    }
}
