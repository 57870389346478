import * as Sentry from "@sentry/browser";

export default class NotificationService {

    /**
     * initialize oneSignal
     * @param app_id
     * @param isLocal
     * @param disableWelcomeNot
     * @returns {Promise<any>}
     */
    static initOneSignal(app_id, isLocal, disableWelcomeNot = false) {
        return new Promise((resolve, reject) => {
            try {
                if (window.OneSignal === undefined) {
                    Sentry.addBreadcrumb({
                        category: 'Push',
                        message: 'OneSignal Undefined',
                        level: 'warning',
                    })
                    return;
                }
                window.OneSignal.push(() => {
                    window.OneSignal.SERVICE_WORKER_PARAM = { scope: '/onesignal/' };
                    window.OneSignal.SERVICE_WORKER_PATH = 'onesignal/OneSignalSDKWorker.js'
                    window.OneSignal.SERVICE_WORKER_UPDATER_PATH = 'onesignal/OneSignalSDKUpdaterWorker.js'
                    window.OneSignal.init({
                        appId: app_id,
                        // safari_web_id: undefined,
                        autoRegister: false,
                        notifyButton: {
                            enable: false,
                        },
                        welcomeNotification: {
                            disable: disableWelcomeNot
                        },
                        allowLocalhostAsSecureOrigin: isLocal,
                    }).catch((e) => {
                        reject(e);
                    });
                });
                resolve();
            } catch (e) {
                reject(e);
            }

        });
    }

    static onPermissionChange() {
        return new Promise((resolve, reject) => {
            window.OneSignal.push( () => {
                window.OneSignal.on('notificationPermissionChange',  (permissionChange) => {
                    let currentPermission = permissionChange.to;
                    resolve(currentPermission);
                });
            })
        })
    }

    /**
     * check whether the user is subscribed to push notifications
     * @returns {Promise<any>}
     */
    static checkSubscription() {
        return new Promise((resolve, reject) => {

            window.OneSignal.push(() => {
                window.OneSignal.isPushNotificationsEnabled().then((isEnabled) => {
                    if (isEnabled) {
                        resolve(true);
                        return;
                    }
                    resolve(false);
                }).catch((e) => {
                    reject(e);
                });
            });
        });
    }

    static showPrompt() {
        window.OneSignal.showNativePrompt();
    }

    /**
     * eventListener on subscriptionChange, return playerId or false
     * @returns {Promise<any | never>}
     */
    static onSubscriptionChange() {
        return new Promise((resolve, reject) => {
            window.OneSignal.push(() => {
                window.OneSignal.on('subscriptionChange', (subscribed) => {
                    if (subscribed) {
                        Sentry.addBreadcrumb({
                            level: 'info',
                            message: 'the user accept push notifications'
                        });
                        window.OneSignal.getUserId().then(playerId => {
                            resolve(playerId);
                        });
                    } else {
                        // the user blocked push notifications
                        Sentry.addBreadcrumb({
                            level: 'info',
                            message: 'the user blocked push notifications'
                        });
                    }
                });
            });
        })
        .catch((e) => {
            Sentry.withScope(function(scope) {
                scope.setTag("NotificationService", "onSubscriptionChange");
                Sentry.captureException(e);
            });
        });
    }
}
