import {environment} from '@/Helpers/enviroments';

const prod = {
    rid: 113,
    campaign_id: 27,
    pwaName: 'Snake Color Brake PWA',
    sharpstarApiUrl: 'https://dc.sharpstar.pro/',
    manifestURL: 'https://lp.snakecolorbrake.com',
    cookieDomain: 'https://lp.snakecolorbrake.com',
    swPath: 'https://lp.snakecolorbrake.com/sw.js',
    link: 'https://lp.snakecolorbrake.com/game/index.html',
    modalsUrl: 'https://enjoythegames.info/wp-json/wp/v2/pages/?slug=',
    onesignalAppId: 'f379f3c3-d329-41d3-95b8-512a2dc1fbb1',
    gaTrackId: 'G-X3BV8078JX',
    lang: 'en'
};

const dev = {
    rid: 93,
    campaign_id: 25,
    pwaName: 'Snake Color Brake PWA Dev',
    cookieDomain: 'https://lpdev.sharpstar.pro/',
    sharpstarApiUrl: 'https://dcdev.sharpstar.pro/',
    link: 'https://lpdev.sharpstar.pro/lena-lp/games/snake-pwa/game/',
    swPath: 'https://lpdev.sharpstar.pro/lena-lp/games/snake-pwa/sw.js',
    manifestURL: 'https://lpdev.sharpstar.pro/lena-lp/games/snake-pwa/',
    modalsUrl: 'https://enjoythegames.info/wp-json/wp/v2/pages/?slug=',
    onesignalAppId: '4aee9612-829c-470f-b497-4b935fac6ecd',
    gaTrackId: 'G-5CN1VCH65Z',
    lang: 'en'
}

let config;

if (environment() === 'production') {
    config = prod;
} else if (environment() === 'development') {
    config = dev;
} else {
    dev.manifestURL = 'https://127.0.0.1:8080';
    dev.cookieDomain = 'https://127.0.0.1:8080';
    dev.onesignalAppId = '3d9fe1c2-a013-4cc7-be09-43b1a5c37e66';
    dev.link = 'http://example.com';
    dev.swPath = './sw.js';
    config = dev;
}


export default config;



