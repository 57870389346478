import React from 'react';
import './reqTypes.scss';
import freeIcon from './assets/free-icon.svg';
import noRegistrationIcon from './assets/registration-icon.svg';
import noPermissionIcon from './assets/permission-icon.svg';

const ReqTypes = () => {
    return (
        <div className={"req-types-container"}>
            <div className={'col'}>
                <div className={'col-con'}>
                    <div className={'icon'}>
                        <img src={freeIcon} width={47} height={46} alt=""/>
                    </div>
                    <div className={'text'}>
                        <p>100% Free - No Credit Card Required</p>
                    </div>
                </div>
            </div>
            <div className={'col'}>
                <div className={'col-con'}>
                    <div className={'icon'}>
                        <img src={noRegistrationIcon} width={47} height={46} alt=""/>
                    </div>
                    <div className={'text'}>
                        <p>No Registration Needed</p>
                    </div>
                </div>

            </div>
            <div className={'col'}>
                <div className={'col-con'}>
                    <div className={'icon'}>
                        <img src={noPermissionIcon} width={47} height={46} alt=""/>
                    </div>
                    <div className={'text'}>
                        <p>No App Permissions Required</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReqTypes;
