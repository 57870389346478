import React from 'react';
import * as Sentry from "@sentry/browser";
import Modal from "@/React/_UI/Modal/Modal";
import WebsiteDataService from "@/Services/WebsiteDataService";
import cloudsImg from "../../assets/clouds.svg";
import clawsImg from "../../assets/claws.svg";
import Bubbles from "./parts/bubbles/Bubbles";
import Header from "./parts/header/Header";
import Footer from "./parts/footer/Footer";
import Articles from "./parts/articles/Articles";
import ActionBox from "./parts/actionBox/ActionBox";
import InstallBox from "./parts/installBox/installBox";
import './welcome.scss';

export default class Welcome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalText: {
                title: '',
                content: ''
            },
            modalOpen: false
        };

        this.websiteDataService = new WebsiteDataService(this.props.modalsUrl, this.props.lang);

        this.toggleModal = this.toggleModal.bind(this);
        this.showModal = this.showModal.bind(this);
    }

    toggleModal() {
        this.setState({
            modalOpen: false,
            modalText: {
                title: '',
                content: ''
            }
        });
    }

    showModal(type) {
        this.setState({
            modalOpen: true,
        });

        this.websiteDataService.getText(type)
            .then((res) => {
                this.setState({
                    modalText: res
                });
            })
            .catch((e) => Sentry.captureException(e));
    }

    componentDidMount() {
        this.props.ga.sendPageView('landing');
    }

    render() {
        return (
            <>
                <div className={'container-fluid'}>
                    <img className={'bg-claws'} src={clawsImg} alt='Claws'/>
                    <img className={'bg-clouds'} src={cloudsImg} alt="Clouds"/>
                    <Header/>
                    <ActionBox
                        playClick={this.props.playClick}
                        playLink={this.props.playLink}
                        onClick={this.props.onClick}
                        isInstalled={this.props.isInstalled}
                        installing={this.props.installing}
                        btnType={this.props.btnType}
                        ga={this.props.ga}
                    />
                    <Bubbles/>
                    <InstallBox
                        isBoxStyle={false}
                        playClick={this.props.playClick}
                        btnType={this.props.btnType}
                        onClick={this.props.onClick}
                        playLink={this.props.playLink}
                        isInstalled={this.props.isInstalled}
                        installing={this.props.installing}
                    />
                    <Articles/>
                    <InstallBox
                        isBoxStyle={false}
                        playClick={this.props.playClick}
                        btnType={this.props.btnType}
                        onClick={this.props.onClick}
                        playLink={this.props.playLink}
                        isInstalled={this.props.isInstalled}
                        installing={this.props.installing}
                    />
                    <Footer
                        isInstalled={this.props.isInstalled}
                        onClick={this.props.playClick}
                        playLink={this.props.playLink}
                        installing={this.props.installing}
                        showModal={this.showModal}
                    />
                </div>
                {this.state.modalOpen &&
                    <Modal
                        title={this.state.modalText.title}
                        content={this.state.modalText.content}
                        onClose={this.toggleModal}
                    />
                }
            </>
        );
    }
}
