import React from "react";
import img_clouds from "../../assets/clouds.svg";
import img_claws from "../../assets/claws.svg";
import * as Sentry from "@sentry/browser";
import WebsiteDataService from "@/Services/WebsiteDataService";
import Modal from "@/React/_UI/Modal/Modal";
import Header from "../welcome/parts/header/Header";
import Footer from "../welcome/parts/footer/Footer";
import SubscribeBox from "./parts/SubscribeBox/SubscribeBox";
import './pushPhase.scss';

export default class PushPhase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalText: {
                title: '',
                content: ''
            },
            modalOpen: false
        };

        this.websiteDataService = new WebsiteDataService(this.props.modalsUrl, this.props.lang);

        this.toggleModal = this.toggleModal.bind(this);
        this.showModal = this.showModal.bind(this);
    }

    toggleModal() {
        this.setState({
            modalOpen: false,
            modalText: {
                title: '',
                content: ''
            }
        });
    }

    showModal(type) {
        this.setState({
            modalOpen: true,
        });
        this.websiteDataService.getText(type)
            .then((res) => {
                this.setState({
                    modalText: res
                });
            })
            .catch((e) => Sentry.captureException(e));
    }

    componentDidMount() {
        this.props.ga.sendPageView('push');
    }

    render() {
        return (
            <>
                <div className={'container-fluid'}>
                    <img className={'bg-clouds'} src={img_clouds} alt="Clouds"/>
                    <img className={'bg-claws'} src={img_claws} alt="Claws"/>

                    <Header/>
                    <SubscribeBox
                        onClick={this.props.onClick}
                        continueOnCLick={this.props.continueOnCLick}
                        gameLink={this.props.gameLink}
                    />
                    <Footer
                        isInstalled={this.props.isInstalled}
                        onClick={this.props.playClick}
                        playLink={this.props.playLink}
                        installing ={this.props.installing}
                        showModal ={this.showModal}
                    />

                </div>
                {this.state.modalOpen &&
                    <Modal
                        title={this.state.modalText.title}
                        content={this.state.modalText.content}
                        onClose={this.toggleModal}
                    />
                }
            </>
        )
    }
}
