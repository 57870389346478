import React from 'react';
import './header.scss';
import img_logo from "./assets/snake-logo-gif.gif";
import img_snake from "./assets/snake.png";

export default class Header extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <div className={'head-container'}>
                    <div className={'container-resizer'}>
                        <img className={'logo'} src={img_logo} alt="Logo"/>
                        <img className={'snake'} src={img_snake} alt="Snake"/>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}