import * as Sentry from "@sentry/browser";

export default class SentryConfig {
    static init(enabled, id, source, campaignId, adBlock, location, env, refid3) {
        if (enabled) {
            Sentry.init({
                dsn: 'https://5b86359019bf4330803e3e33b36d936e@sentry.io/1415478',
                environment: env,
                whitelistUrls: [
                    /https?:\/\/((udata|lpdev)\.)?sharpstar\.(pro|dev)/,
                    location
                ],
                beforeSend(event, hint) {
                    event.extra = event.extra || {}

                    if (hint.originalException != null) {
                        // Assign enumerable error properties to extras
                        try {
                            const keys = Object.keys(hint.originalException)
                            if (keys.length) {
                                event.extra.errorProperties = {}
                                keys.forEach(key => {
                                    event.extra.errorProperties[key] = hint.originalException[key]
                                })
                            }
                        } catch (error) {
                            console.warn('[sentry] Failed to assign enumerable error properties to extras', error)
                        }
                    }

                    let exceptions = event.exception;
                    if (exceptions) {
                        let values = exceptions.values[exceptions.values.length - 1];
                        //check if exceptions has values
                        if (values) {
                            let stacktrace = values.stacktrace;
                            //check if exception has values
                            if (stacktrace) {
                                let frames = stacktrace.frames[stacktrace.frames.length - 1];
                                //check if stacktrace has frames
                                if (frames) {
                                    let fileName = frames.filename;
                                    //check if stacktrace has file name
                                    if (fileName && event.tags) {
                                        // error_source tag to sentry event
                                        event.tags.error_source = fileName;
                                    }
                                }
                            }
                        }
                    }
                    return event;
                }
            });

            Sentry.configureScope((scope) => {
                scope.setUser({"id": id});
                scope.setTag('source', source);
                scope.setTag('campaign_id', campaignId);
                scope.setTag('blocking-ads', adBlock);
                scope.setTag('refid3', refid3);
            });
        }
    }
}