import React, {useEffect, useState} from 'react';
import snakeRed from "./assets/snake-red.png";
import snakeBlue from "./assets/snake-blue.png";
import snakeYellow from "./assets/snake-yellow.png";
import snakeGreen from "./assets/snake-green.png";
import snakePink from "./assets/snake-pink.png";
import {getRandomElement} from "@/helpers";
import './snakePicker.scss';

const snakeImgs = [snakeRed, snakeBlue, snakeYellow, snakeGreen, snakePink];
const colors = ['red', 'blue', 'yellow', 'green', 'pink'];

const SnakePicker = ({ snakeSelected, selectSnake }) => {
    const [pulseIndex, setPulseIndex] = useState(3);

    useEffect(() => {
        let timeout;

        if (snakeSelected) {
            setPulseIndex(null);
        } else {
            timeout = setTimeout(() => {
                const element =  getRandomElement(5, pulseIndex);

                setPulseIndex(element);
            }, 1900);
        }

        return () => clearTimeout(timeout);
    }, [pulseIndex])

    return (
        <>
            <div className={'snake-box-title'}>
                <h3>Choose your favorite color and play</h3>
            </div>
            <div className={"snake-types-container"}>
                <div className={'snake-type-resizer'}>
                    {
                        snakeImgs.length > 0 && snakeImgs.map((img, index) => {
                            return (
                                <div className={'col'} key={index}>
                                    <div className={colors.indexOf(snakeSelected) === index ? 'selected' : ''}>
                                        <img
                                            alt=''
                                            src={img}
                                            width={44}
                                            height={94}
                                            className={index === pulseIndex ? 'pulse' : ''}
                                            onClick={() => selectSnake(colors[index])}
                                        />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default SnakePicker;
