import polyglot from '@/Helpers/languages';

export default class WebsiteDataService {
    constructor(url, lang) {
        this.url = url;
        this.lang = lang;
    }

    texts = {};

    setModalObject(title, content) {
        return {
            title, content
        };
    }

    getSlug(type) {
        switch (type) {
            case 'about':
                return polyglot.t(this.lang + '.modals_slug.about_us');
            case 'privacy':
                return polyglot.t(this.lang + '.modals_slug.privacy_policy');
            case 'terms':
                return polyglot.t(this.lang + '.modals_slug.terms');
            case 'cookies':
                return 'cookie-policy'
        }
    }

    getText(type) {
        return new Promise((resolve, reject) => {
            if (this.texts[type]) {
                resolve(this.texts[type]);
            }
            const slug = this.getSlug(type);
            const reqUrl = this.url + slug;
            fetch(reqUrl)
                .then((res) => res.json())
                .then((data) => {
                    const text = this.setModalObject(data[0].title.rendered, data[0].content.rendered);
                    this.texts[type] = text;
                    resolve(text);
                }).catch((e) => reject(e));
        })
    }
}
