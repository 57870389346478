import React from "react";
import './articles.scss';
import divider from './assets/divider.png';
import gameImage from './assets/game.png';
import card1 from './assets/card1.png';
import card2 from './assets/card2.png';
import card3 from './assets/card3.png';

export default class Articles extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={'articles-container'}>
                <div>
                    <div className={'section-divider'}>
                        <img src={divider} alt="Divider"/>
                    </div>
                    <div className={'article-1-row'}>
                        <div className="col-left">
                            <img src={gameImage} width={484} height={642} alt="Game"/>
                        </div>
                        <div className="col-right">
                            <h4>Play Snake Color Brake via desktop and mobile!</h4>
                            <p className={'p-margin'}>Do you want to test your dexterity skills, yet having the best time while playing? Try Snake Color Brake, an addictive game created especially for those who love challenges. You can chose the desktop version, where you have to press the mouse or can simply try the mobile game. </p>

                            <h4>What do I have to do in this game?</h4>
                            <p>The answer is simply! You have five colours (blue, yellow, red, green and purple) in the upper side of the screen. A sliding had moves in the bottom side of the screen, where it is a color. Click on the Start button right in that moment when the hand overlaps the color. Now the game is on! Drive the snake through the correct color up the next level. Do you think it is easy?</p>
                            <p>Surprise! The color of the snake changes! Enjoy the game via desktop and mobile – the coolest game for everyone! Get Snake Color Brake for free and become the Snake Master!</p>
                        </div>
                    </div>
                    <div className={'section-divider'}>
                        <img src={divider} width={225} height={23} alt="Divider"/>
                    </div>
                    <div className={'article-2-row'}>
                        <div className={'row article'}>
                            <div className="col">
                            </div>
                            <div className="col">
                                <h4>100% free challenge! Can you beat the switching coloured Snake?</h4>
                               </div>
                        </div>
                        <div className={'row article'}>
                            <div className="col img">
                                <img src={card1} width={484} height={158} alt=""/>
                            </div>
                            <div className="col">
                                <p className={'p-margin-2'}>Five colors, one Snake, biggest challenge! Drive the Snake trough the correct color and get points. Sounds easy? Don’t think so! The Snake is sneaky, as will surprise you each move he does – now his color is green, then suddenly, the snake turns red or blue. Do you want to see how challenging this game is?</p>
                            </div>
                        </div>
                        <div className={'row article'}>
                            <div className="col img">
                                <img src={card2} width={462} height={145} alt=""/>
                            </div>
                            <div className="col">
                                <p className={'p-margin-2'}>Try it now for free! Moreover, instant play via desktop and mobile. No need to register or allow us access to your contacts, agenda, messages or anything else. Just pure fun for everyone! Get the game now and play it with your friends to see who can achieve the highest score at Snake Color Brake.</p>
                            </div>
                        </div>
                        <div className={'row article'}>
                            <div className="col img">
                                <img src={card3} width={484} height={158} alt=""/>
                            </div>
                            <div className="col">
                                <p>One of the best titles of the year, most challenging game for young and old, nice graphics and cool action – you can play it on your desktop, as well on your mobile phone. Can you get to the next level of Snake Color Brake?</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}