import React from 'react';
import './bubbles.scss';
import bubble_1 from "./assets/vector1.png";
import bubble_snake_2 from "./assets/snake2.png";
import bubble_2 from "./assets/vector2.png";
import bubble_snake_1 from "./assets/snake1.png";
import bubble_3 from "./assets/vector3.png";
import bubble_snake_3 from "./assets/snake3.png";

export default class Bubbles extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={'bubbles-container'}>
                <div className={'container-resizer'}>
                    <div className={'bubble bubble1'}>
                        <img className={'bubble-bg'} src={bubble_1} alt="bubble"/>
                        <div className={'bubble-body'}>
                            <div>
                                <img src={bubble_snake_2} alt="Snake"/>
                            </div>
                            <div className={'p-container'}>
                                <p>Challenge yourself and see how many points you can collect, set a high score or simply practise your snake crawling skills.</p>
                            </div>
                        </div>
                    </div>
                    <div className={'bubble bubble2'}>
                        <img className={'bubble-bg'} src={bubble_2} alt="bubble"/>
                        <div className={'bubble-body'}>
                            <div>
                                <img src={bubble_snake_1} alt="Snake"/>
                            </div>
                            <div className={'p-container'}>
                                <p>All you need to do is to move the snake to the colored box that matched the current snake color. Keep in mind that your snake changes its color often and the boxes order is not constant.</p>
                            </div>
                        </div>
                    </div>
                    <div className={'bubble bubble3'}>
                        <img className={'bubble-bg'} src={bubble_3} alt="bubble"/>
                        <div className={'bubble-body'}>
                            <div className={'p-container'}>
                                <p>Sounds easy? Start playing now and see if you can handle the color breaks in this free, addictive, venom-packed gaming experience!</p>
                            </div>
                            <div>
                                <img src={bubble_snake_3} alt="Snake"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}